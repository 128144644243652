<template>
    <!-- @click='toggleFullScreen' -->
    <section id='video_detail'>
        <div class="detail_slide_box">
            <vue-lvjs
                ref='videoPlayer'
                v-if="m3u8_url"
                class="lvjs_player"
                :m3u8="m3u8_url"
                :f4v-url="f4vUrl"
                :width='width'
                :height='height'
                :videoType="videoType"
                @video-ready="videoReady"
                :options="options">
            </vue-lvjs>
        </div>
        <!-- :before-close="cancelConfirm" -->
        <el-dialog
            title=""
            class='password_dialog'
            :visible.sync="passwordConfirmVisible"
            :close-on-click-modal="false"
        >
            <div class='tips'>请输入密码查看文件（请联系智能制作部索取密码）</div>
            <el-input type="text" size='medium' placeholder='输入播放密码，数字或字母' v-model="password" @keyup.enter.native="onSubmit"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </section>
</template>

<script>
import { checkPassword } from '@/api/video.js'
import * as configApi from '@/api/config.js'

export default {
    name: 'sliderBox',
    data () {
        return {
            videoList: [],
            curVideo: null,
            passwordConfirmVisible: false,
            password: '',
            videoType: 'native', // native, notPreview
            videoId: +this.$route.query.videoId,
            m3u8_url: '',
            // m3u8_url: '/mams-publish-platform/publish/getM3U8FileContent?userId=15773316645&videoId=59&remoteIp=10.11.22.33',
            // videoType: 'notPreview',
            // m3u8_url: 'http://lego.iqiyi.com/external/meta-video/20180710/03/31/151601c086dc357132a37cecd9ba812e.m3u8',
            f4vUrl: '',
            options: {
                controlBar: {
                    fullscreenToggle: true,
                    displayStyle: {
                        dynamic: true,
                        bottom: false
                    }
                },
                playsinline: true,
                language: 'zh-CN',
                poster: '',
                muted: false,
                isFullscreen: true,
                autoplay: true, // 是否自动播放
                controls: true, // 是否展示整个控制条
                inactivityTimeout: 1, // 为0表示控制条会始终存在
                bigPlayButton: true // 不自动播放时是否在画面左上角展示一个大的播放按钮
            },
            player: '',
            width: 0,
            height: 0
        }
    },
    watch: {
    },
    computed: {
        needPassword () {
            if (this.curVideo) {
                const arr = this.videoList.filter(v => v.checkPassword === 1).map(v => v.videoId)
                return arr.includes(this.curVideo.videoId)
            } else {
                return false
            }
        }
    },
    created () {
        this.width = document.documentElement.clientWidth
        this.height = document.documentElement.clientHeight
        this.queryType = this.$route.query.queryType
        this.getConfigQuery()
    },
    methods: {
        getConfigQuery () {
            configApi.getConfigQuery(this.queryType).then(res => {
                this.videoList = res.data
                this.curVideo = res.data.find(v => v.id === this.$route.query.id)
                if (this.needPassword) {
                    this.passwordConfirmVisible = true
                } else {
                    this.m3u8_url = '/mams-publish-platform/publish/idp/getM3U8FileContent?newsConfigId=' + this.curVideo.id + '&videoId=' + this.curVideo.videoId
                }
            })
        },
        videoReady (player, id) {
            this.player = player
        },
        onSubmit () {
            const param = {
                newsConfigId: this.curVideo.id,
                videoId: this.curVideo.videoId,
                password: this.password,
                checkPassword: 1
            }
            checkPassword(param).then(res => {
                console.log(res)
                if (res && res.code === 'A00000') {
                    this.m3u8_url = '/mams-publish-platform/publish/idp/getM3U8FileContent?newsConfigId=' + this.curVideo.id + '&videoId=' + this.curVideo.videoId + '&password=' + this.password
                    this.passwordConfirmVisible = false
                }
            })
        },
        // 点击切换全屏
        toggleFullScreen () {
            console.log('this.player.isFullscreen()', this.player.isFullscreen())
            if (this.player.isFullscreen()) {
                this.player.exitFullscreen()
            } else {
                this.player.requestFullscreen()
            }
        },
        // 暂时没用
        launchFullscreen () {
            var element = document.documentElement
            if (element.requestFullScreen) {
                element.requestFullScreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen()
            }
            var videobox = document.getElementsByTagName('video')[0]
            console.log('videobox', videobox)
            videobox.style.cssText = 'width: 100vw;height:100vh'
        }
    }

}
</script>

<style lang="scss">
#video_detail{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    .lvjs_player{
        width: 100%;
        height: 100%;
    }
    // 自适应
    .lvjs-vue .video-js .vjs-tech {
        object-fit: contain;
    }
    // 播放按钮样式
    .vjs-paused .vjs-big-play-button{
        display: block!important; border-radius: 50%; border: 1px;
        line-height: 3em; height: 3em; width: 3em;
        // opacity: 0.7; background: #000;
    }
    // 密码输入框样式
    .password_dialog{
        .el-dialog{
            width: 400px!important;
        }
        .el-dialog__header{
            display: none;
        }
        .tips{
            margin: 20px 0;
            text-align: left;
        }
    }
    .el-input.is-active .el-input__inner, .el-input__inner:focus {
        border-color: #00cc36;
        outline: 0;
    }
    .el-button--primary{
        background-color: #00cc36;
        border-color: #00cc36;
    }

    /**
    兼容safari
     */
    .vjs-resize-manager{ // 隐藏 safri 12.0.1 版本浏览器上播放时上面那个白色的框
        opacity: 0;
    }
}
</style>
